/* PrismJS 1.29.0
https://prismjs.com/download.html#themes=prism-twilight&languages=markup+css+clike+javascript */
code[class*="editor-code"],
pre[class*="editor-code"] {
  /* display: none !important; */
  color: #fff;
  background: 0 0;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  /* text-shadow: 0 -0.1em 0.2em #000; */
  /* white-space: pre; */
  /* word-spacing: normal; */
  /* word-break: normal; */
  /* word-wrap: normal; */
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
:not(pre) > code[class*="editor-code"],
pre[class*="editor-code"] {
  background: #141414;
}
pre[class*="editor-code"] {
  border-radius: 0.5em;
  border: 0.3em solid #545454;
  box-shadow: 1px 1px 0.5em #000 inset;
  margin: 0.5em 0;
  overflow: auto;
  padding: 1em;
}
pre[class*="editor-code"]::-moz-selection {
  background: #27292a;
}
pre[class*="editor-code"]::selection {
  background: #27292a;
}
code[class*="editor-code"] ::-moz-selection,
code[class*="editor-code"]::-moz-selection,
pre[class*="editor-code"] ::-moz-selection,
pre[class*="editor-code"]::-moz-selection {
  text-shadow: none;
  background: hsla(0, 0%, 93%, 0.15);
}
code[class*="editor-code"] ::selection,
code[class*="editor-code"]::selection,
pre[class*="editor-code"] ::selection,
pre[class*="editor-code"]::selection {
  text-shadow: none;
  background: hsla(0, 0%, 93%, 0.15);
}
:not(pre) > code[class*="editor-code"] {
  border-radius: 0.3em;
  border: 0.13em solid #545454;
  box-shadow: 1px 1px 0.3em -0.1em #000 inset;
  /* padding: 0.15em 0.2em 0.05em; */
  /* white-space: normal; */
}
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #777;
}
.token.punctuation {
  opacity: 0.7;
}
.token.namespace {
  opacity: 0.7;
}
.token.boolean,
.token.deleted,
.token.number,
.token.tag {
  color: #ce6849;
}
.token.builtin,
.token.constant,
.token.keyword,
.token.property,
.token.selector,
.token.symbol {
  color: #f9ed99;
}
.editor-codecss .token.string,
.style .token.string,
.token.attr-name,
.token.attr-value,
.token.char,
.token.entity,
.token.inserted,
.token.operator,
.token.string,
.token.url,
.token.variable {
  color: #909e6a;
}
.token.atrule {
  color: #7385a5;
}
.token.important,
.token.regex {
  color: #e8c062;
}
.token.bold,
.token.important {
  font-weight: 700;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
.editor-codemarkup .token.attr-name,
.editor-codemarkup .token.punctuation,
.editor-codemarkup .token.tag {
  color: #ac885c;
}
.token {
  position: relative;
  z-index: 1;
}
.line-highlight.line-highlight {
  background: hsla(0, 0%, 33%, 0.25);
  background: linear-gradient(
    to right,
    hsla(0, 0%, 33%, 0.1) 70%,
    hsla(0, 0%, 33%, 0)
  );
  border-bottom: 1px dashed #545454;
  border-top: 1px dashed #545454;
  margin-top: 0.75em;
  z-index: 0;
}
.line-highlight.line-highlight:before,
.line-highlight.line-highlight[data-end]:after {
  background-color: #8693a6;
  color: #f4f1ef;
}
