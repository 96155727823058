body {
  margin: 0;
  font-family:
    "Inter",
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  background: var(--bgColor);
}

code {
  background-color: var(--translucent);
  width: 100%;
  flex: 1;
  /* padding:25px; */
}

.is-sticky {
  border-radius: 0 0 15px 15px !important;
  /* padding: 20px 30px !important;
  padding-right: 0px !important; */
}

@media (max-width: 900px) {
  .is-sticky {
    background: var(--glassGradientHeader);
    border: 1px solid var(--glassBorder);
    box-shadow: var(--lightShadow);
    padding: 10px 10px !important;
  }
}

:root {
  --coreWidth: 1300px;
  --coreWidthSmall: 1000px;
  --chapterWidth: 750px;
  --mainPadding: 255px;
  --mobilePadding: 17px;
  --headerWidth: 120px;
  --headerWidthLoggedOut: 150px;
  --headerHeight: 110px;
  --toastify-color-progress-dark: #333333 !important;
}

.MuiInputBase-root:before {
  display: none !important;
}

.MuiInputBase-root {
  overflow: hidden !important;
}

/* .MuiIconButton-edgeEnd {
  color: #999999 !important ;
}

.MuiInput-underline:before {
  border-bottom: 1px solid var(--translucentHard) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #999 !important;
}
.MuiFormLabel-root {
  color: #999 !important;
}





.MuiInputBase-input {
  overflow: hidden !important;
} */

* {
  box-sizing: border-box;
  outline: none;
  /* font-size: 21px;
  line-height: 27px; */
}

html,
body {
  overflow-x: hidden;
}

@keyframes strikeAnimation {
  from {
    width: 0%;
  }
  to {
    width: 89%;
  }
}

@keyframes lineContainerFadeAway {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

html {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 50px;
}

iframe {
  border: none !important;
}

.chrome-picker {
  width: 100% !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color);
  opacity: 0.8; /* Firefox */
}

.MuiSlider-root {
  color: var(--color) !important;
}

.MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgba(210, 118, 25, 0.16) !important;
}

.MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px rgba(210, 118, 25, 0.16) !important;
}

.react-colorful {
  width: 100% !important;
  box-shadow: var(--hardShadow);
  border-radius: 10px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* /// */

.code-action-menu-container button.menu-item {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
  background: none;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}

.code-action-menu-container button.menu-item i.format {
  height: 16px;
  width: 16px;
  opacity: 0.6;
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  background-size: contain;
}

.code-action-menu-container button.menu-item:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 0.9;
}

.code-action-menu-container button.menu-item:active {
  background-color: rgba(223, 232, 250);
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.code-action-menu-container .prettier-wrapper {
  position: relative;
}

.code-action-menu-container .prettier-wrapper .code-error-tips {
  padding: 5px;
  border-radius: 4px;
  color: #fff;
  background: #222;
  margin-top: 4px;
  position: absolute;
  top: 26px;
  right: 0;
}
